import fileInMinutesJSON from '@/assets/rebrand/lottie/1 - File in minutes.js';
import complexityToClarityJSON from '@/assets/rebrand/lottie/2 - Complexity to clarity.js';
import comprehensiveCoverageJSON from '@/assets/rebrand/lottie/3 - Comprehensive coverage.js';
import saveMoneyBoostWealthJSON from '@/assets/rebrand/lottie/4 - Save money, boost wealth.js';
import { CreditBlock } from '@/components/Rebrand/CreditBlock';
import { CallToActionButton } from '@/components/Rebrand/LandingCTAs';
import {
  Body2,
  CTABlockContainer,
  CTAPrefixTitle,
  isClient,
  LANDING_SOCIAL_TESTIMONIALS,
  LandingImage,
  PartnersLanding,
  SnapPointEnd,
  SocialTestimonialBlock,
  Ticker,
} from '@cointracker/styleguide';
import { LandingCTATitle } from '@cointracker/styleguide/src/CTABlock/CTABlock';
import { Grid } from '@cointracker/styleguide/src/Grid';
import { usePushFade } from '@cointracker/styleguide/src/hooks/usePushFade';
import coinbaseIconUrl from '@cointracker/styleguide/src/icons/partners/Coinbase.svg?url';
import hrBlockIconUrl from '@cointracker/styleguide/src/icons/partners/H&R Block.svg?url';
import intuitIconUrl from '@cointracker/styleguide/src/icons/partners/intuit-turbo-tax-squared.svg?url';
import metamaskIconUrl from '@cointracker/styleguide/src/icons/partners/MetaMask.svg?url';
import openseaIconUrl from '@cointracker/styleguide/src/icons/partners/OpenSea.svg?url';
import phantomIconUrl from '@cointracker/styleguide/src/icons/partners/Phantom.svg?url';
import solanaIconUrl from '@cointracker/styleguide/src/icons/partners/Solana Foundation.svg?url';
import uniswapIconUrl from '@cointracker/styleguide/src/icons/partners/Uniswap.svg?url';
import { Heading } from '@cointracker/styleguide/src/LoggedIn';
import { COLORS } from '@cointracker/styleguide/src/themePrimitives.css';
import { REBRAND_URLS, URLS, useSearchParams } from '@cointracker/ui';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import { useEffect, useRef, useState } from 'react';
import headerUrl from 'src/assets/rebrand/landing-header-unoptimized.webp?url';
import Soc1Icon from 'src/assets/rebrand/soc-1-type-2-light.svg?react';
import Soc2Icon from 'src/assets/rebrand/soc-2-type-2-light.svg?react';
import { trackSignUpButtonClicked } from 'src/common/analytics';
import { AppSnapPoint } from 'src/components/AppSnapPoint';
import { useGate } from 'src/hooks/statsig';
import { useAuth } from 'src/hooks/useAuth';
import { usePartnerStore } from 'src/pages-react/landing/partnerStore';
import {
  determineDiscountText,
  getPartner,
  processPartnerSpecificURLParams,
  setCookies,
} from 'src/pages-react/landing/utils';
import { CBO_PROMO_KEY } from '../../../pages-react/CoinbaseOnePromotionPage/CoinbaseOnePromotionPage/constants';
import {
  LandingFeature,
  LandingFeatureButton,
  LandingFeatureContainer,
  LandingFeatureSubtitle,
  LandingFeatureTitle,
} from '../LandingFeature';
import { useFeatureState } from '../LandingFeature/utils';
import { CoinbaseOneModal } from '../Partner/CoinbaseOneModal';
import { useBackgroundFade } from './hooks/useBackgroundFade';
import {
  landingHeaderContainer,
  landingImageBlock,
  partnersContainer,
  securityLogos,
} from './LandingFlow.css';
import { LandingHeaderCopy } from './LandingHeaderCopy';
import { getPartnerFeature } from './utils';

const FEATURES_SECTIONS = ['Speed', 'Ease', 'Accuracy', 'Growth'];

interface LandingFlowProps {
  partnerSlug?: string;
}

export const LandingFlow = (props: LandingFlowProps) => {
  const { partnerSlug } = props;

  const [searchParams] = useSearchParams();
  const { setPartner } = usePartnerStore();
  const { isAuthenticated } = useAuth();
  const isCoinbaseOne = partnerSlug === 'coinbaseone';
  const hasPromoKeyInStorage =
    isClient() && Boolean(localStorage.getItem(CBO_PROMO_KEY));
  const hasPromoKeyInURL = searchParams.has('c');
  const shouldAutoPop =
    isCoinbaseOne && (hasPromoKeyInStorage || hasPromoKeyInURL);
  const [isOpen, setIsOpen] = useState(shouldAutoPop);

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };
  const { value: earlyBirdDiscount } = useGate('early_bird_discount_2025');

  useEffect(() => {
    if (partnerSlug) {
      const partner = getPartner(partnerSlug);
      if (!partner) return;

      processPartnerSpecificURLParams(partnerSlug, searchParams);
      partner.userId = searchParams.get('partner_id') ?? undefined;
      setPartner(partner);
      setCookies(partner, searchParams.get('utm_source') ?? undefined);
      if (isAuthenticated) {
        // redirect to dashboard
        window.location.href = `${URLS.HOME_PAGE}${window.location.search}`;
      }
    }
  }, [searchParams, setPartner, partnerSlug, isAuthenticated]);

  const partnerFeature = getPartnerFeature(
    partnerSlug,
    earlyBirdDiscount,
    toggleModal,
  );

  const landingHeaderCopyRef = useRef<HTMLDivElement>(null);
  const landingImageRef = useRef<HTMLDivElement>(null);
  const partnersRef = useRef<HTMLDivElement>(null);
  const securityRef = useRef<HTMLDivElement>(null);
  const creditBlockRef = useRef<HTMLDivElement>(null);
  const testimonialRef = useRef<HTMLDivElement>(null);
  const bottomCTARef = useRef<HTMLDivElement>(null);

  const { ref: fileInMinutesRef, state: fileInMinutesState } =
    useFeatureState();
  const { ref: complexityToClarityRef, state: complexityToClarityState } =
    useFeatureState();
  const { ref: comprehensiveCoverageRef, state: comprehensiveCoverageState } =
    useFeatureState();
  const { ref: saveMoneyBoostWealthRef, state: saveMoneyBoostWealthState } =
    useFeatureState();

  useBackgroundFade();

  usePushFade(creditBlockRef.current);
  usePushFade(securityRef.current);
  usePushFade(bottomCTARef.current, {
    ease: 'cointrackerBounce',
    duration: 0.4,
    interval: 0.45,
    delay: 0.45,
  });

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    // Landing copy
    const tlLandingHeader = gsap.timeline({
      scrollTrigger: {
        trigger: landingImageRef.current,
        start: 0,
        end: 300,
        scrub: true,
      },
    });
    tlLandingHeader.fromTo(
      landingHeaderCopyRef.current,
      {
        autoAlpha: 1,
      },
      {
        autoAlpha: 0,
      },
    );

    return () => {
      tlLandingHeader.kill();
    };
  }, []);

  const discountText = determineDiscountText({
    earlyBirdDiscount,
  });
  const showTicker = earlyBirdDiscount && discountText;

  return (
    <div>
      <CoinbaseOneModal toggleModal={toggleModal} isOpen={isOpen} />
      {showTicker && (
        <Ticker className="absolute w-full">{discountText}</Ticker>
      )}
      <AppSnapPoint topBarSpace></AppSnapPoint>
      <div className={landingHeaderContainer} ref={landingHeaderCopyRef}>
        <LandingHeaderCopy
          partnerSlug={partnerSlug}
          toggleModal={toggleModal}
        />
      </div>

      <AppSnapPoint allowReset snapTo="end">
        <div ref={partnersRef}>
          {!partnerSlug && (
            <Grid className={landingImageBlock}>
              <div ref={landingImageRef}>
                <LandingImage
                  imgSrc={headerUrl}
                  imgAlt="Futuristic abstract design with layered panels, floating shapes, and a figure interacting with a glowing interface"
                />
              </div>
            </Grid>
          )}

          {partnerSlug && <div ref={landingImageRef}>{partnerFeature}</div>}
          <div className={partnersContainer}>
            <PartnersLanding
              title="Official partners"
              topPartners={[
                { logoUrl: coinbaseIconUrl, text: 'Coinbase' },
                { logoUrl: intuitIconUrl, text: 'Intuit TurboTax' },
                { logoUrl: hrBlockIconUrl, text: 'H&R Block' },
                { logoUrl: uniswapIconUrl, text: 'Uniswap' },
              ]}
              bottomPartners={[
                { logoUrl: openseaIconUrl, text: 'Open Sea' },
                { logoUrl: metamaskIconUrl, text: 'MetaMask' },
                { logoUrl: phantomIconUrl, text: 'Phantom' },
                { logoUrl: solanaIconUrl, text: 'Solana Foundation' },
              ]}
            />
          </div>
        </div>
      </AppSnapPoint>

      <LandingFeatureContainer>
        <AppSnapPoint allowReset>
          <div ref={fileInMinutesRef}>
            <LandingFeature
              sections={FEATURES_SECTIONS}
              sectionIndex={0}
              imgAlt="File in minutes"
              lottieData={fileInMinutesJSON}
              snapState={fileInMinutesState}
              isLottie
              noFadeIn
            >
              <LandingFeatureTitle>File in minutes</LandingFeatureTitle>
              <LandingFeatureSubtitle>
                CoinTracker is designed for efficiency so that you can file with
                TurboTax, H&R Block, or your own tax professional.
              </LandingFeatureSubtitle>
              <LandingFeatureButton
                onClick={() => {
                  trackSignUpButtonClicked(
                    'Get started',
                    'landing-feature',
                    'File in minutes',
                    'homepage',
                  );
                  window.location.href = REBRAND_URLS.GET_STARTED;
                }}
              >
                Get started
              </LandingFeatureButton>
            </LandingFeature>
          </div>
        </AppSnapPoint>
        <AppSnapPoint allowReset>
          <div ref={complexityToClarityRef}>
            <LandingFeature
              sections={FEATURES_SECTIONS}
              sectionIndex={1}
              imgAlt="Clarity, not complexity"
              lottieData={complexityToClarityJSON}
              snapState={complexityToClarityState}
              isLottie
            >
              <LandingFeatureTitle>
                Simplicity, not complexity
              </LandingFeatureTitle>
              <LandingFeatureSubtitle>
                View all your crypto activity in one place and get a clear
                picture of your balance, performance, and taxes &mdash;
                automatically.
              </LandingFeatureSubtitle>
              <LandingFeatureButton
                onClick={() => {
                  trackSignUpButtonClicked(
                    'See more',
                    'landing-feature',
                    'Clarity, not complexity',
                    'homepage',
                  );
                  window.location.href = REBRAND_URLS.GET_STARTED;
                }}
              >
                See more
              </LandingFeatureButton>
            </LandingFeature>
          </div>
        </AppSnapPoint>
        <AppSnapPoint allowReset>
          <div ref={comprehensiveCoverageRef}>
            <LandingFeature
              sections={FEATURES_SECTIONS}
              sectionIndex={2}
              imgAlt="Comprehensive coverage"
              lottieData={comprehensiveCoverageJSON}
              snapState={comprehensiveCoverageState}
              isLottie
            >
              <LandingFeatureTitle>Comprehensive coverage</LandingFeatureTitle>
              <LandingFeatureSubtitle>
                CoinTracker auto-categorizes your latest DeFi transactions,
                removes spam, and integrates with 10,000 crypto assets to ensure
                your taxes are done right.
              </LandingFeatureSubtitle>
              <LandingFeatureButton
                onClick={() => {
                  trackSignUpButtonClicked(
                    'Get the full picture',
                    'landing-feature',
                    'Comprehensive coverage',
                    'homepage',
                  );
                  window.location.href = REBRAND_URLS.GET_STARTED;
                }}
              >
                Get the full picture
              </LandingFeatureButton>
            </LandingFeature>
          </div>
        </AppSnapPoint>
        <AppSnapPoint allowReset>
          <div ref={saveMoneyBoostWealthRef}>
            <LandingFeature
              sections={FEATURES_SECTIONS}
              sectionIndex={3}
              imgAlt="Save money, boost wealth"
              lottieData={saveMoneyBoostWealthJSON}
              snapState={saveMoneyBoostWealthState}
              isLottie
              noFadeOut
            >
              <LandingFeatureTitle>
                Save money, boost wealth
              </LandingFeatureTitle>
              <LandingFeatureSubtitle>
                Get more money back in your pocket with personalized insights
                and performance-tracking tools
              </LandingFeatureSubtitle>
              <LandingFeatureButton
                onClick={() => {
                  trackSignUpButtonClicked(
                    'Track portfolio',
                    'landing-feature',
                    'Save money, boost wealth',
                    'homepage',
                  );
                  window.location.href = REBRAND_URLS.GET_STARTED;
                }}
              >
                Track portfolio
              </LandingFeatureButton>
            </LandingFeature>
          </div>
        </AppSnapPoint>
      </LandingFeatureContainer>

      <AppSnapPoint>
        <div data-bg-color={COLORS.blue[20]}>
          <CTABlockContainer noBackground>
            <div ref={creditBlockRef}>
              <LandingCTATitle>
                <div>Making crypto taxes</div>
                <div>pain-free since 2017</div>
              </LandingCTATitle>
              <div data-push-fade-item>
                <CreditBlock />
              </div>
            </div>
          </CTABlockContainer>
        </div>
      </AppSnapPoint>

      <AppSnapPoint>
        <div ref={securityRef} data-bg-color={COLORS.lilac[10]}>
          <CTABlockContainer noBackground>
            <CTAPrefixTitle>Security at every step</CTAPrefixTitle>
            <LandingCTATitle>
              Customers around the
              <br />
              world trust us to keep their
              <br />
              data secure
            </LandingCTATitle>
            <Body2
              variant="secondary"
              className="text-center [&&]:ml-auto [&&]:mr-auto [&&]:mt-8 [&&]:max-w-[480px]"
            >
              With read-only access to your wallets, end-to-end encryption, and
              token-based two-factor authentication, your data is in safe hands.
            </Body2>
            <div className={securityLogos} data-push-fade-item>
              <Soc1Icon />
              <Soc2Icon />
            </div>
          </CTABlockContainer>
        </div>
      </AppSnapPoint>

      <AppSnapPoint>
        <div ref={testimonialRef} data-bg-color={COLORS.lime[10]}>
          <SocialTestimonialBlock
            title="Don't just take our word for it"
            subtext="What our customers are saying"
            testimonials={LANDING_SOCIAL_TESTIMONIALS}
            fullscreen
            animated
          />
        </div>
      </AppSnapPoint>

      <AppSnapPoint>
        <div data-bg-color={COLORS.lime[20]}>
          <CTABlockContainer
            noBackground
            className="[&&]:min-h-[650px]"
            fullscreen
          >
            <div ref={bottomCTARef}>
              <Heading
                variant="h1"
                as="h2"
                className="flex flex-col place-items-center justify-center gap-4 text-center"
              >
                <span>Save time.</span>
                <span data-push-fade-item>Save money.</span>
              </Heading>
              <div data-push-fade-item>
                <Heading
                  variant="h5"
                  className="mt-24 max-w-[592px] text-center"
                >
                  Switch to the most accurate crypto subledger available
                </Heading>
                <div className="text-center">
                  <CallToActionButton
                    href={REBRAND_URLS.GET_STARTED}
                    buttonText="Start for free"
                    pageTitle="consumer landing"
                    sectionName="SaveTimeMoneyCTA"
                    sectionTitle="Save time. Save money."
                  >
                    Start for free
                  </CallToActionButton>
                </div>
              </div>
            </div>
          </CTABlockContainer>
        </div>
      </AppSnapPoint>

      <SnapPointEnd />
    </div>
  );
};
