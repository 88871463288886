import {
  Button,
  cn,
  DecorativeRow,
  LandingTitle,
} from '@cointracker/styleguide';
import {
  buildGetStartedUrl,
  isClient,
  URLS,
  useSearchParams,
} from '@cointracker/ui';
import { CBO_PROMO_KEY } from 'src/pages-react/CoinbaseOnePromotionPage/CoinbaseOnePromotionPage/constants';
import { getNextUrl } from 'src/pages-react/landing/utils';
import { getLandingCopyProps } from './utils';

import { Body, Heading } from '@cointracker/styleguide/src/LoggedIn';
import { trackSignUpButtonClicked } from 'src/common/analytics';
import { useGate } from 'src/hooks/statsig';
import { usePartnerStore } from 'src/pages-react/landing/partnerStore';
import { PartnerLogo } from '../Partner/PartnerLogo';

const DEFAULT_TITLE = 'Crypto taxes done right';
const DEFAULT_BUTTON_TITLE = 'Start for free';

interface LandingHeaderCopyProps {
  partnerSlug?: string;
  toggleModal?: () => void;
}

export const LandingHeaderCopy = (props: LandingHeaderCopyProps) => {
  const { partnerSlug, toggleModal } = props;
  const { value: earlyBirdDiscount } = useGate('early_bird_discount_2025');
  const headerCopyProps = getLandingCopyProps({
    partner: partnerSlug,
    earlyBirdDiscount,
  });

  const [searchParams] = useSearchParams();
  const { partner } = usePartnerStore();
  const nextUrl = getNextUrl(searchParams, partner);
  const hasCBOPromoCode = isClient() && localStorage.getItem(CBO_PROMO_KEY);
  const getStartedUrl = buildGetStartedUrl(
    hasCBOPromoCode
      ? URLS.COINBASE_ONE_PROMOTION
      : partnerSlug === 'coinbaseone'
        ? URLS.EXTERNAL_COINBASE_ONE_BOUNCE_LINK
        : nextUrl,
  );
  const buttonText =
    (headerCopyProps.registerButtonTitle ?? headerCopyProps.discountText)
      ? (headerCopyProps.registerButtonTitle ?? 'Start for free')
      : DEFAULT_BUTTON_TITLE;

  const trackSignup = () => {
    trackSignUpButtonClicked(
      buttonText,
      'hero',
      headerCopyProps.title ?? DEFAULT_TITLE,
      headerCopyProps.title ?? DEFAULT_TITLE,
    );
  };

  const hasSubDiscountText = !!headerCopyProps.discountText?.length;
  return (
    <div
      className={cn(
        'px-16 pb-40 pt-80 text-center md:px-32 md:pt-112',
        earlyBirdDiscount && 'pt-120 md:pt-space-144',
      )}
    >
      {partnerSlug && <PartnerLogo partnerSlug={partnerSlug} />}
      <LandingTitle>{headerCopyProps.title || DEFAULT_TITLE}</LandingTitle>
      <Heading
        variant="h6"
        className="mx-auto mt-12 max-w-[682px] text-pretty text-center"
      >
        {headerCopyProps.subtitle}
      </Heading>
      {hasSubDiscountText && (
        <Body className="text-text-secondary" variant="body5">
          {headerCopyProps.discountText}
        </Body>
      )}
      <DecorativeRow partnerSlug={partnerSlug} className="mt-16 md:mt-24" />
      <Button
        className="mt-40"
        onClick={() => {
          trackSignup();
          if (toggleModal && partnerSlug === 'coinbaseone') {
            toggleModal();
          } else {
            window.location.href = getStartedUrl;
          }
        }}
      >
        {buttonText}
      </Button>
    </div>
  );
};
