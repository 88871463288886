import type { ReactNode } from 'react';
import { determineDiscountText } from 'src/pages-react/landing/utils';
import { PartnerFeature, SolanaTable } from '../Partner';
import blockchainFeature from '../Partner/featureimg/blockchain.com.webp?url';
import braveFeature from '../Partner/featureimg/brave.webp?url';
import coinbaseFeature from '../Partner/featureimg/coinbase.webp?url';
import coinbaseOneFeature from '../Partner/featureimg/coinbaseone.webp?url';
import coinjarFeature from '../Partner/featureimg/coinjar.webp?url';
import coinsquareFeature from '../Partner/featureimg/coinsquare.webp?url';
import etoroFeature from '../Partner/featureimg/etoro.webp?url';
import hrblockFeature from '../Partner/featureimg/hrblock.webp?url';
import metamaskFeature from '../Partner/featureimg/metamask.webp?url';
import openseaFeature from '../Partner/featureimg/opensea.webp?url';
import phantomFeature from '../Partner/featureimg/phantom.webp?url';
import solanaFeature from '../Partner/featureimg/solana.webp?url';
import trustWalletFeature from '../Partner/featureimg/trust-wallet.webp?url';
import turbotaxFeature from '../Partner/featureimg/turbotax.webp?url';
import uniswapFeature from '../Partner/featureimg/uniswap.webp?url';
import upholdFeature from '../Partner/featureimg/uphold.webp?url';
export type LandingCopy = {
  title?: string;
  subtitle?: string;
  discountText?: string;
  additionalSection?: (toggleModel?: () => void) => React.ReactNode | null;
  registerButtonTitle?: string;
  toggleModal?: () => void;
  earlyBirdDiscount?: boolean;
};

const EARLY_BIRD_TITLE = '20% off all plans';
const EARLY_BIRD_CTA_BUTTON = 'Save 20% on all plans';
const PARTNERS_20 = [
  'coinbaseone',
  'coinbase',
  'phantom',
  'brave',
  'trust-wallet',
  'uniswap',
  'hrblock',
  'solana',
];
// coinjar was also 20 but it's not active so not on the list.
const PARTNERS_15 = ['turbotax', 'uphold', 'opensea'];
//  defined in case it comes in handy later + it's just good for glancing at
// const PARTNERS_10 = [
//   'etoro',
//   'blockchain.com',
//   'uphold',
//   'coinsquare',
//   'metamask',
//   'coinjar',
// ];

const imageMap = {
  coinbaseone: coinbaseOneFeature,
  coinbase: coinbaseFeature,
  solana: solanaFeature,
  turbotax: turbotaxFeature,
  hrblock: hrblockFeature,
  etoro: etoroFeature,
  phantom: phantomFeature,
  brave: braveFeature,
  'blockchain.com': blockchainFeature,
  uphold: upholdFeature,
  opensea: openseaFeature,
  'trust-wallet': trustWalletFeature,
  uniswap: uniswapFeature,
  coinsquare: coinsquareFeature,
  metamask: metamaskFeature,
  coinjar: coinjarFeature,
};

export const getLandingCopyProps = ({
  partner,
  earlyBirdDiscount,
}: {
  partner?: string;
  earlyBirdDiscount?: boolean;
}): LandingCopy => {
  switch (partner) {
    case 'coinbaseone':
      return {
        title: earlyBirdDiscount
          ? EARLY_BIRD_TITLE
          : 'Claim your free 2025 plan',
        subtitle:
          'Coinbase One members get a free Base plan ($59 value). Coinbase One Premium members get a free Prime plan ($199 value) plus a 15-minute account health review call.',
        discountText: determineDiscountText({
          partnerDiscountText: `Expires December 31st, 2025.`,
          earlyBirdDiscount,
        }),
        registerButtonTitle: 'Claim now',
      };
    case 'coinbase':
      return {
        title: earlyBirdDiscount ? EARLY_BIRD_TITLE : '20% off all plans',
        subtitle:
          'Plus a free upgrade to premium features, including performance tracking, tax loss harvesting, and tax lots. That’s up to $140 in value.',
        discountText: determineDiscountText({
          partnerDiscountText:
            'Offer valid for new customers only. Expires Dec 31, 2025.',
        }),
      };
    case 'turbotax':
      return {
        title: earlyBirdDiscount ? EARLY_BIRD_TITLE : '15% off all plans',
        subtitle:
          'Join 2.5M+ people who save time, money, and headaches with CoinTracker.',
        discountText: determineDiscountText({
          partnerDiscountText:
            'Offer valid for new customers only. Expires Dec 31, 2025.',
          earlyBirdDiscount,
        }),
      };
    case 'etoro':
      return {
        title: earlyBirdDiscount ? EARLY_BIRD_TITLE : '10% off all plans',
        subtitle:
          'Join 2.5M+ people who save time, money, and headaches with CoinTracker.',
        discountText: determineDiscountText({
          partnerDiscountText:
            'Offer valid for new customers only. Expires Dec 31, 2025.',
          earlyBirdDiscount,
        }),
      };
    case 'phantom':
      return {
        title: earlyBirdDiscount ? EARLY_BIRD_TITLE : '20% off all plans',
        subtitle:
          'Join 2.5M+ people who save time, money, and headaches with CoinTracker.',
        discountText: determineDiscountText({
          partnerDiscountText:
            'Offer valid for new customers only. Expires Dec 31, 2025.',
          earlyBirdDiscount,
        }),
      };
    case 'brave':
      return {
        title: earlyBirdDiscount ? EARLY_BIRD_TITLE : '20% off all plans',
        subtitle:
          'Join 2.5M+ people who save time, money, and headaches with CoinTracker.',
        discountText: determineDiscountText({
          partnerDiscountText:
            'Offer valid for new customers only. Expires Dec 31, 2025.',
          earlyBirdDiscount,
        }),
      };
    case 'blockchain.com':
      return {
        title: earlyBirdDiscount ? EARLY_BIRD_TITLE : '10% off all plans',
        subtitle:
          'Join 2.5M+ people who save time, money, and headaches with CoinTracker.',
        discountText: determineDiscountText({
          partnerDiscountText:
            'Offer valid for new customers only. Expires Dec 31, 2025.',
          earlyBirdDiscount,
        }),
      };
    case 'uphold':
      return {
        title: earlyBirdDiscount ? EARLY_BIRD_TITLE : '10% off all plans',
        subtitle:
          'Join 2.5M+ people who save time, money, and headaches with CoinTracker.',
        discountText: determineDiscountText({
          partnerDiscountText:
            'Offer valid for new customers only. Expires Dec 31, 2025.',
          earlyBirdDiscount,
        }),
      };
    case 'opensea':
      return {
        title: earlyBirdDiscount ? EARLY_BIRD_TITLE : '15% off all plans',
        subtitle:
          'Join 2.5M+ people who save time, money, and headaches with CoinTracker.',
        discountText: determineDiscountText({
          partnerDiscountText:
            'Offer valid for new customers only. Expires Dec 31, 2025.',
          earlyBirdDiscount,
        }),
      };
    case 'trust-wallet':
      return {
        title: earlyBirdDiscount ? EARLY_BIRD_TITLE : '20% off all plans',
        subtitle:
          'Join 2.5M+ people who save time, money, and headaches with CoinTracker.',
        discountText: determineDiscountText({
          partnerDiscountText:
            'Offer valid for new customers only. Expires Dec 31, 2025.',
          earlyBirdDiscount,
        }),
      };
    case 'uniswap':
      return {
        title: earlyBirdDiscount ? EARLY_BIRD_TITLE : '20% off all plans',
        subtitle:
          'Join 2.5M+ people who save time, money, and headaches with CoinTracker.',
        discountText: determineDiscountText({
          partnerDiscountText:
            'Offer valid for new customers only. Expires Dec 31, 2025.',
          earlyBirdDiscount,
        }),
      };
    case 'hrblock':
      return {
        title: earlyBirdDiscount ? EARLY_BIRD_TITLE : '20% off all plans',
        subtitle:
          'Join 2.5M+ people who save time, money, and headaches with CoinTracker.',
        discountText: determineDiscountText({
          partnerDiscountText:
            'Offer valid for new customers only. Expires Dec 31, 2025.',
          earlyBirdDiscount,
        }),
      };
    case 'coinsquare':
      return {
        title: earlyBirdDiscount ? EARLY_BIRD_TITLE : '10% off all plans',
        subtitle:
          'Join 2.5M+ people who save time, money, and headaches with CoinTracker.',
        discountText: determineDiscountText({
          partnerDiscountText:
            'Offer valid for new customers only. Expires Dec 31, 2025.',
          earlyBirdDiscount,
        }),
      };
    case 'metamask': {
      return {
        title: earlyBirdDiscount ? EARLY_BIRD_TITLE : '10% off all plans',
        subtitle:
          'Join 2.5M+ people who save time, money, and headaches with CoinTracker.',
        discountText: determineDiscountText({
          partnerDiscountText:
            'Offer valid for new customers only. Expires Dec 31, 2025.',
          earlyBirdDiscount,
        }),
      };
    }
    case 'solana': {
      return {
        title: earlyBirdDiscount ? EARLY_BIRD_TITLE : '20% off all plans',
        subtitle:
          'Join 2.5M+ people who save time, money, and headaches with CoinTracker.',
        discountText: determineDiscountText({
          partnerDiscountText:
            'Offer valid for new customers only. Expires Dec 31, 2025.',
          earlyBirdDiscount,
        }),
      };
    }
    case 'canada': {
      return {
        title: 'Crypto & NFT Taxes for Canada',
        subtitle:
          'Easily sync wallets and generate CRA tax forms. Trusted by 1 million+ users',
      };
    }
    case 'taxes-uk': {
      return {
        title: 'Crypto & NFT Taxes for the UK',
        subtitle:
          'Easily sync wallets and prepare HRMC forms. Trusted by 1 million+ users',
      };
    }
    case 'coinjar': {
      const isBeforeApril52025 =
        new Date().getTime() < new Date('2025-04-05T00:00-08:00').getTime();
      return {
        title: earlyBirdDiscount
          ? EARLY_BIRD_TITLE
          : isBeforeApril52025
            ? '10% off all plans'
            : 'Crypto taxes made clear',
        subtitle:
          'Join 2.5M+ people who save time, money, and headaches with CoinTracker.',
        discountText: determineDiscountText({
          partnerDiscountText:
            'Offer valid for new customers only. Expires Apr 5, 2025.',
          invalidAfterDate: '2025-04-05T00:00-08:00',
          earlyBirdDiscount,
        }),
      };
    }
    default: {
      return {
        title: 'Crypto taxes made clear',
        subtitle: '2.5M+ people save time and money with CoinTracker',
        discountText: determineDiscountText({
          earlyBirdDiscount,
        }),
      };
    }
  }
};

export const getPartnerFeature = (
  partnerSlug?: string,
  earlyBirdDiscount?: boolean,
  toggleModal?: () => void,
): ReactNode | null => {
  const disclaimerText = earlyBirdDiscount
    ? 'Offer valid for new customers only. Expires Jan 31, 2025.'
    : partnerSlug === 'coinjar'
      ? 'Offer valid for new customers only. Expires Apr 5, 2025.'
      : 'Offer valid for new customers only. Expires Dec 31, 2025.';
  const is20 = partnerSlug ? PARTNERS_20.includes(partnerSlug) : false;
  const is15 = partnerSlug ? PARTNERS_15.includes(partnerSlug) : false;
  const saveButtonText = is20
    ? 'Save 20% on all plans'
    : is15
      ? 'Save 15% on all plans'
      : 'Save 10% on all plans';
  switch (partnerSlug) {
    case 'coinbaseone':
      return (
        <PartnerFeature
          title="Join 2.5M+ people who trust CoinTracker"
          subtitles={[
            'CoinTracker is the official crypto tax partner of Coinbase.',
            'Here’s how it works:',
          ]}
          sublist={[
            'Connect your Coinbase account, exchanges, and wallets — support for 500+ crypto integrations',
            'Get your optimized and accurate tax forms',
            'File directly with TurboTax, H&R Block, or your CPA',
          ]}
          heroImageUrl={imageMap[partnerSlug]}
          buttonText={earlyBirdDiscount ? EARLY_BIRD_CTA_BUTTON : 'Get started'}
          disclaimerText={`Expires December 31st, 2025.`}
          toggleModal={toggleModal}
        />
      );
    case 'coinbase':
      return (
        <PartnerFeature
          title="Join 2.5M+ people who trust CoinTracker"
          subtitles={[
            'CoinTracker is the official crypto tax partner of Coinbase.',
            'Here’s how it works:',
          ]}
          sublist={[
            'Connect your Coinbase account, exchanges, and wallets — support for 500+ crypto integrations',
            'Get your optimized and accurate tax forms',
            'File directly with TurboTax, H&R Block, or your CPA',
          ]}
          heroImageUrl={imageMap[partnerSlug]}
          buttonText={
            earlyBirdDiscount ? EARLY_BIRD_CTA_BUTTON : 'Save 20% on all plans'
          }
          disclaimerText={disclaimerText}
        />
      );
    case 'solana':
      return (
        <>
          <PartnerFeature
            title="Take it easy this tax season"
            subtitles={[
              'CoinTracker is the official crypto tax partner of Solana.',
              'Here’s how it works:',
            ]}
            sublist={[
              'Connect your Solana wallets, exchanges, and other wallets — support for 500+ crypto integrations',
              'Get your optimized and accurate tax forms',
              'File directly with TurboTax, H&R Block, or your CPA',
            ]}
            heroImageUrl={imageMap[partnerSlug]}
            buttonText={
              earlyBirdDiscount
                ? EARLY_BIRD_CTA_BUTTON
                : 'Save 20% on all plans'
            }
            disclaimerText={disclaimerText}
          />
          <SolanaTable />
        </>
      );
    case 'turbotax':
      return (
        <PartnerFeature
          title="Take it easy this tax season"
          subtitles={[
            'CoinTracker is the official crypto tax partner of TurboTax.',
            'Here’s how it works:',
          ]}
          sublist={[
            'Connect your wallet and exchanges — support for 500+ crypto integrations',
            'Get your optimized and accurate tax forms',
            'File directly with TurboTax',
          ]}
          heroImageUrl={imageMap[partnerSlug]}
          buttonText={
            earlyBirdDiscount ? EARLY_BIRD_CTA_BUTTON : 'Save 15% on all plans'
          }
          disclaimerText={disclaimerText}
        />
      );
    case 'hrblock':
      return (
        <PartnerFeature
          title="Take it easy this tax season"
          subtitles={[
            'CoinTracker is the official crypto tax partner of H&R Block.',
            'Here’s how it works:',
          ]}
          sublist={[
            'Connect your wallet and exchanges — support for 500+ crypto integrations',
            'Get your optimized and accurate tax forms',
            'File directly with H&R Block',
          ]}
          heroImageUrl={imageMap[partnerSlug]}
          buttonText={
            earlyBirdDiscount ? EARLY_BIRD_CTA_BUTTON : 'Save 15% on all plans'
          }
          disclaimerText={disclaimerText}
        />
      );
    case 'uphold':
      return (
        <PartnerFeature
          title="Take it easy this tax season"
          subtitles={[
            'CoinTracker is the official crypto tax partner of Uphold.',
            'Here’s how it works:',
          ]}
          sublist={[
            'Connect your Uphold account, exchanges, and wallets — support for 500+ crypto integrations',
            'Get your optimized and accurate tax forms',
            'File directly with TurboTax, H&R Block, or your CPA',
          ]}
          heroImageUrl={imageMap[partnerSlug]}
          buttonText={
            earlyBirdDiscount ? EARLY_BIRD_CTA_BUTTON : saveButtonText
          }
          disclaimerText={disclaimerText}
        />
      );
    case 'etoro':
      return (
        <PartnerFeature
          title="Take it easy this tax season"
          subtitles={[
            'CoinTracker is the official crypto tax partner of eToro.',
            'Here’s how it works:',
          ]}
          sublist={[
            'Connect your eToro account, exchanges, and wallets — support for 500+ crypto integrations',
            'Get your optimized and accurate tax forms',
            'File directly with TurboTax, H&R Block, or your CPA',
          ]}
          heroImageUrl={imageMap[partnerSlug]}
          buttonText={
            earlyBirdDiscount ? EARLY_BIRD_CTA_BUTTON : saveButtonText
          }
          disclaimerText={disclaimerText}
        />
      );
    case 'opensea':
      return (
        <PartnerFeature
          title="Take it easy this tax season"
          subtitles={[
            'CoinTracker is the official crypto tax partner of OpenSea.',
            'Here’s how it works:',
          ]}
          sublist={[
            'Connect your wallets and exchanges — support for 500+ crypto integrations',
            'Get your optimized and accurate tax forms',
            'File directly with TurboTax, H&R Block, or your CPA',
          ]}
          heroImageUrl={imageMap[partnerSlug]}
          buttonText={
            earlyBirdDiscount ? EARLY_BIRD_CTA_BUTTON : saveButtonText
          }
          disclaimerText={disclaimerText}
        />
      );
    case 'trust-wallet':
      return (
        <PartnerFeature
          title="Take it easy this tax season"
          subtitles={[
            'CoinTracker is the official crypto tax partner of Trust.',
            'Here’s how it works:',
          ]}
          sublist={[
            'Connect your TrustWallet, exchanges, and other wallets — support for 500+ crypto integrations',
            'Get your optimized and accurate tax forms',
            'File directly with TurboTax, H&R Block, or your CPA',
          ]}
          heroImageUrl={imageMap[partnerSlug]}
          buttonText={
            earlyBirdDiscount ? EARLY_BIRD_CTA_BUTTON : saveButtonText
          }
          disclaimerText={disclaimerText}
        />
      );
    case 'uniswap':
      return (
        <PartnerFeature
          title="Take it easy this tax season"
          subtitles={[
            'CoinTracker is the official crypto tax partner of Uniswap.',
            'Here’s how it works:',
          ]}
          sublist={[
            'Connect your Uniswap Wallets and exchanges — support for 500+ crypto integrations',
            'Get your optimized and accurate tax forms',
            'File directly with TurboTax, H&R Block, or your CPA',
          ]}
          heroImageUrl={imageMap[partnerSlug]}
          buttonText={
            earlyBirdDiscount ? EARLY_BIRD_CTA_BUTTON : saveButtonText
          }
          disclaimerText={disclaimerText}
        />
      );
    case 'phantom':
      return (
        <PartnerFeature
          title="Take it easy this tax season"
          subtitles={[
            'CoinTracker is the official crypto tax partner of Phantom.',
            'Here’s how it works:',
          ]}
          sublist={[
            'Connect your Phantom wallet, exchanges, and other wallets — support for 500+ crypto integrations',
            'Get your optimized and accurate tax forms',
            'File directly with TurboTax, H&R Block, or your CPA',
          ]}
          heroImageUrl={imageMap[partnerSlug]}
          buttonText={
            earlyBirdDiscount ? EARLY_BIRD_CTA_BUTTON : saveButtonText
          }
          disclaimerText={disclaimerText}
        />
      );
    case 'brave':
      return (
        <PartnerFeature
          title="Take it easy this tax season"
          subtitles={[
            'CoinTracker is the official crypto tax partner of Brave.',
            'Here’s how it works:',
          ]}
          sublist={[
            'Connect your wallets and exchanges — support for 500+ crypto integrations',
            'Get your optimized and accurate tax forms',
            'File directly with TurboTax, H&R Block, or your CPA',
          ]}
          heroImageUrl={imageMap[partnerSlug]}
          buttonText={
            earlyBirdDiscount ? EARLY_BIRD_CTA_BUTTON : saveButtonText
          }
          disclaimerText={disclaimerText}
        />
      );
    case 'blockchain.com':
      return (
        <PartnerFeature
          title="Take it easy this tax season"
          subtitles={[
            'CoinTracker is the official crypto tax partner of Blockchain.com.',
            'Here’s how it works:',
          ]}
          sublist={[
            'Connect your Blockchain.com account, exchanges, and wallets — support for 500+ crypto integrations',
            'Get your optimized and accurate tax forms',
            'File directly with TurboTax, H&R Block, or your CPA',
          ]}
          heroImageUrl={imageMap[partnerSlug]}
          buttonText={
            earlyBirdDiscount ? EARLY_BIRD_CTA_BUTTON : saveButtonText
          }
          disclaimerText={disclaimerText}
        />
      );
    case 'coinsquare':
      return (
        <PartnerFeature
          title="Take it easy this tax season"
          subtitles={[
            'CoinTracker is the official crypto tax partner of Coinsquare.',
            'Here’s how it works:',
          ]}
          sublist={[
            'Connect your CoinsSquare Account, exchanges, and wallets — support for 500+ crypto integrations',
            'Get your optimized and accurate tax forms',
            'File directly with TurboTax, H&R Block, or your CPA',
          ]}
          heroImageUrl={imageMap[partnerSlug]}
          buttonText={
            earlyBirdDiscount ? EARLY_BIRD_CTA_BUTTON : saveButtonText
          }
          disclaimerText={disclaimerText}
        />
      );
    case 'metamask': {
      return (
        <PartnerFeature
          title="Take it easy this tax season"
          subtitles={[
            'CoinTracker is the official crypto tax partner of Metamask.',
            'Here’s how it works:',
          ]}
          sublist={[
            'Connect your MetaMask wallet, exchanges, and other wallets — support for 500+ crypto integrations',
            'Get your optimized and accurate tax forms',
            'File directly with TurboTax, H&R Block, or your CPA',
          ]}
          heroImageUrl={imageMap[partnerSlug]}
          buttonText={
            earlyBirdDiscount ? EARLY_BIRD_CTA_BUTTON : saveButtonText
          }
          disclaimerText={disclaimerText}
        />
      );
    }
    case 'coinjar':
      return (
        <PartnerFeature
          title="Take it easy this tax season"
          subtitles={[
            'CoinTracker is the official crypto tax partner of CoinJar.',
            'Here’s how it works:',
          ]}
          sublist={[
            'Connect your exchanges and wallets — support for 500+ crypto integrations',
            'Get your optimized and accurate tax forms',
            'File directly with TurboTax, H&R Block, or your CPA',
          ]}
          heroImageUrl={imageMap[partnerSlug]}
          buttonText={
            earlyBirdDiscount ? EARLY_BIRD_CTA_BUTTON : saveButtonText
          }
          disclaimerText={disclaimerText}
        />
      );
    case 'canada':
    case 'taxes-uk':
    default:
      // no partner or no discount currently.
      return null;
  }
};
